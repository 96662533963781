import React, { useState, useEffect, useRef, useContext, useCallback, useLayoutEffect } from "react"
import { Dot, getDuration, getLSM, transformBin, getWarning, getCreated, canFit } from "./Helpers"
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { WebSocketContext } from './WebSocket';
import "./OneUp.css";
import Popover from 'react-tiny-popover'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { motion } from "framer-motion"
import {
    draggingStarted,
    draggingEnded,
    fontSizeUpdated,
    transformBins1,
    reset
} from './actions'

import "./WaitingOverlay.css";
import Modal from 'react-modal';
import Spinner from "react-spinkit"
import { isFloat, isInt } from 'validator';
import { VERSION } from './config.js'
const ROW_STATE_BUSY = 4
const ROW_STATE_UNDO_SHOWN = 3
const ROW_STATE_DONE_SHOWN = 2
const ROW_STATE_LOCKED = 1
const ROW_STATE_IDLE = 0
function Preferences({ user, userType, exit, logout }) {
    const fontSize = useSelector(state => state.fontSize)
    const [localFontSize, setLocalFontSize] = useState(localStorage.getItem("fontSize") ? localStorage.getItem("fontSize") : "16.0" )
    const dispatch = useDispatch()
    const bin1 = useSelector(state => state.bin1)
    const settings = useSelector(state => state.settings)
    const ws = useContext(WebSocketContext)
    const [separatorIndex, setSeparatorIndex] = useState(11)


    function fontSizeCheckInput(e) {
        if (isFloat(e.target.value) || e.target.value === "") {
            setLocalFontSize(e.target.value + "")
        } else {
            return
        }
    }
    function saveNewFontSize() {
        if (parseFloat(fontSize) < 5.0) {
            setLocalFontSize("5.0")
        } else if (parseFloat(fontSize) > 24.0) {
            setLocalFontSize("24.0")
        }
        dispatch(fontSizeUpdated(localFontSize))
        
    }

    function fontSizeOnKeyPress(e) {
        if (e.key === "Enter") {
            saveNewFontSize()
        }
    }
    function fontSizePlus() {
        setLocalFontSize(Math.round((parseFloat(localFontSize) + 0.1) * 10) / 10 + "")
        saveNewFontSize()
    }
    function fontSizeMinus() {
        setLocalFontSize(Math.round((parseFloat(localFontSize) - 0.1) * 10) / 10 + "")
        saveNewFontSize()
    }
    function separatorCheckInput(e) {
        if (isInt(e.target.value) || e.target.value === "") {
            setSeparatorIndex(parseInt(e.target.value))
        } else {
            return
        }
    }
    function separatorPlus() {
        let newIndex = separatorIndex + 1
        ws.adminMarkerUpdated(bin1, newIndex)
        setSeparatorIndex(newIndex)
    }
    function separatorMinus() {
        let newIndex = Math.max(separatorIndex - 1, 0)
        ws.adminMarkerUpdated(bin1, newIndex)
        setSeparatorIndex(newIndex)
    }
    useEffect(() => {
        if (settings[bin1.id]) {
            if (typeof settings[bin1.id].rowIndex === "string" && isInt(settings[bin1.id].rowIndex)) {
                setSeparatorIndex(parseInt(settings[bin1.id].rowIndex))
            } else {
                setSeparatorIndex(settings[bin1.id].rowIndex)
            }

        }
    }, [settings, bin1])

    useEffect(() => {
        document.body.style.fontSize = fontSize+"px";
    }, [fontSize])

    function getInfo() {
        return `${user.name} (${user.roles.join(", ")}) ${VERSION}`
    }


    return (
        <div className="oneUp__preferencespopovercontainer">
            <div className="oneUp__preferencespopover">
                <div className="oneUp_settingcontainer">
                    <div className="oneUp_prefsettingtext">Row Size</div>
                    <div className="oneUp__rowsizecontrolscontainer">
                        <img onClick={fontSizeMinus} className="oneUp__rowsizeminuscontainer" alt="minus" src="images/minus.png" />
                        <input className="oneUp__fontsizeinput" value={localFontSize} onChange={fontSizeCheckInput} onKeyDown={fontSizeOnKeyPress}></input>
                        <img onClick={fontSizePlus} alt="plus" src="images/plus.png" className="oneUp__rowsizepluscontainer" />
                    </div>
                </div>
                <div className="oneUp_settingcontainer">
                    <div className="oneUp_prefsettingtext">Separater Index</div>
                    <div className="oneUp__rowsizecontrolscontainer">
                        <img onClick={separatorMinus} className="oneUp__rowsizeminuscontainer" alt="minus" src="images/minus.png" style={userType === 0 ? { display: "none" } : null} />
                        <input disabled className="oneUp__fontsizeinput" value={separatorIndex ? separatorIndex : 11} onChange={separatorCheckInput}></input>
                        <img onClick={separatorPlus} alt="plus" src="images/plus.png" style={userType === 0 ? { display: "none" } : null} className="oneUp__rowsizepluscontainer" />
                    </div>
                </div>
                {/* <div className="oneUp_settingcontainer">
                    <div className="oneUp_prefsettingtext">View Type</div>
                    <div className="oneUp__rowsizecontrolscontainer">
                        <Link to="/twoup">Two Panel</Link>
                    </div>
                </div> */}
                <div className="oneUp_settingcontainer">
                    <div className="oneUp_prefsettingtext oneUp__prefinfo">{getInfo()}</div>
                    <div className="oneUp__rowsizecontrolscontainer">
                        <div className="oneUp_logout" onClick={logout} ><img alt="logout" src="images/logout.png" /></div>
                    </div>
                </div>
            </div>
        </div>
    )
}
function BinList({ exit }) {
    const bins = useSelector(state => state.bins)
    const ws = useContext(WebSocketContext);
    function selectedBin(e) {
        exit()
        if (e.currentTarget.getAttribute("binindex")) {
            localStorage.setItem('binindex', e.currentTarget.getAttribute("binindex"))
        }
        ws.selectedBinIDs([bins[e.currentTarget.getAttribute("binindex")].id])
    }
    return (
        <div className="oneUp__binpopover">
            <div className="oneUp__binpopover-container">
                {bins.map((item, key) =>
                    <div onClick={selectedBin} className="oneUp__binpopovername" key={item.id} binindex={key} binid={item.id}><span className="oneUp__binpopovername-text">{item.title}</span></div>
                )}
            </div>
        </div>
    )
}
function Header({ user, userType, logout }) {
    const bin1 = useSelector(state => state.bin1)
    const [isBinPopoverOpen, setIsBinPopoverOpen] = useState(false)
    const [isPreferencesPopoverOpen, setIsPreferencesPopoverOpen] = useState(false)
    const status = useSelector(state => state.status)
    function fullscreenMode() {
        var elem = document.documentElement
        if (elem.requestFullscreen) {
            elem.requestFullscreen()
        } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen()
        } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen()
        } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen()
        }
    }
    return <div className="oneUp__header">
        <Dot status={status} />
        <div onClick={fullscreenMode} className="oneUp__fullscreenbutton">
            <img alt="fullscreen button" src="images/fullscreen.png" />
        </div>
        <Popover
            transitionDuration={0.000001}
            isOpen={isBinPopoverOpen}
            position={'bottom'} // preferred position
            onClickOutside={() => setIsBinPopoverOpen(false)}
            content={<BinList exit={() => { setIsBinPopoverOpen(false) }}></BinList>}>
            <div onClick={() => setIsBinPopoverOpen(true)} className="oneUp__header-text">{bin1 && bin1.title ? bin1.title : ""}<img className="oneUp__dropdown" alt="change bin" src="images/dropdown.png" /></div>
        </Popover>

        <Popover
            isOpen={isPreferencesPopoverOpen}
            // isOpen={true}
            position={'bottom'} // preferred position
            onClickOutside={() => setIsPreferencesPopoverOpen(false)}
            content={<Preferences user={user} userType={userType} logout={logout} exit={() => { setIsPreferencesPopoverOpen(false) }}></Preferences>}>
            <img onClick={() => setIsPreferencesPopoverOpen(!isPreferencesPopoverOpen)} className="oneUp__gear" alt="preferences" src="images/gear.png" />
        </Popover>
    </div>
}
function LargeRow({ userType, asset, index, lastActiveIndex, setLastActiveIndex, busyIndex, setBusyIndex, toggleMarked, played }) {
    const [disclosureState, setDisclosureState] = useState(0)
    useEffect(() => {
        if (!asset) {
            setDisclosureState(ROW_STATE_IDLE)
        } else if (busyIndex === index) { //if this row is busy, wait
            setDisclosureState(ROW_STATE_BUSY)
        } else if (lastActiveIndex === index) {//if this row is shown the undo or done button
            if (played) {
                setDisclosureState(ROW_STATE_UNDO_SHOWN)
            } else {
                setDisclosureState(ROW_STATE_DONE_SHOWN)
            }

        } else if (busyIndex >= 0) { //if some other row is busy, lock this row
            setDisclosureState(ROW_STATE_LOCKED)
        } else {
            setDisclosureState(ROW_STATE_IDLE)
        }
    }, [disclosureState, lastActiveIndex, busyIndex, index, played, asset])

    const nameAnimationVariations = {
        disclosureButtonVisible: {
            x: "2.75em"
        },
        disclosureBusyVisible: {
            x: "2.25em"
        },
        disclosureHidden: {
            x: "0em"
        }
    }

    const doneAnimationVariations = {
        doneVisible: {
            opacity: 1
        },
        doneHidden: {
            opacity: 0

        }
    }

    function clicked() {
        if (!asset || userType === 0) {
            return
        }
        switch (disclosureState) {
            case ROW_STATE_BUSY:
            case ROW_STATE_LOCKED:
                return
            case ROW_STATE_UNDO_SHOWN:
            case ROW_STATE_DONE_SHOWN:
                setLastActiveIndex(-1)
                break
            case ROW_STATE_IDLE:
                setLastActiveIndex(index)
                break
            default:
                setLastActiveIndex(-1)
                break
        }
    }
    function buttonClicked(e) {
        setLastActiveIndex(-1)
        setBusyIndex(parseInt(e.target.getAttribute("value")))
        toggleMarked(asset.id, !asset.played)
    }
    function disclosureShown() {
        return disclosureState > 1
    }
    function getNameAnimateState() {
        switch (disclosureState) {
            case ROW_STATE_BUSY:
                return "disclosureBusyVisible"
            case ROW_STATE_DONE_SHOWN:
            case ROW_STATE_UNDO_SHOWN:
                return "disclosureButtonVisible"
            default:
                return "disclosureHidden"
        }
    }
    return (
        <div onClick={clicked}
            className={"oneUp__table-row-content" + (played ? " oneUp__table-row-content-played" : "")}>
            <span className="oneUp__tablerowbodyspan oneUp__table-body-name">
                <motion.div
                    className="oneUp__donebuttoncontainer"
                    animate={disclosureShown() ? "doneVisible" : "doneHidden"}
                    initial="doneHidden"
                    transition={{ duration: 0.25, ease: "easeOut", delay: 0 }}
                    variants={doneAnimationVariations}
                >
                    {disclosureState === ROW_STATE_DONE_SHOWN ?
                        <img className={"oneUp_doneButton"} value={index} src="images/doneButton.png" alt="done button" onClick={buttonClicked}></img>
                        :
                        disclosureState === ROW_STATE_UNDO_SHOWN ?
                            <img className={"oneUp_doneButton"} value={index} src="images/undoButton.png" alt="undo button" onClick={buttonClicked}></img>
                            :
                            busyIndex === index ?
                                <Spinner fadeIn={"none"} className="oneUp__spincontainer" name="folding-cube" color="yellow" />
                                :
                                null
                    }
                </motion.div>

                <motion.span
                    className="oneUp__table-body-nametext"
                    animate={getNameAnimateState()}
                    initial="disclosureHidden"
                    variants={nameAnimationVariations}
                    transition={{ type: 'spring', stiffness: 100, mass: 0.5, velocity: 5 }}
                >
                    {asset ? asset.title : ""}
                </motion.span>

                {/* TODO: Possible optimization here with getWarning() */}

                {getWarning(asset) ? 
                <span className="oneUp__table-body-warning-single">
                    {/* <img className="oneUp__table-alert" alt="warning" src="/images/alert.png" /> */}
                    {/* <span> */}
                        {getWarning(asset)}
                    {/* </span> */}
                </span>: <span></span>}

            </span>
            <span className="oneUp__bodyseparator"></span>
            <span className="oneUp__tablerowbodyspan oneUp__table-lsm">
                {getLSM(asset)}
            </span>
            <span className="oneUp__bodyseparator"></span>
            <span className="oneUp__tablerowbodyspan oneUp__table-duration">
                {getDuration(asset)}
            </span>
            <span className="oneUp__bodyseparator"></span>
            <span className="oneUp__tablerowbodyspan oneUp__table-created">
                {getCreated(asset)}
            </span>
        </div>
    )
}
function LargeRowWarning({ userType, asset, index, lastActiveIndex, setLastActiveIndex, busyIndex, setBusyIndex, toggleMarked, played }) {
    const [disclosureState, setDisclosureState] = useState(0)
    useEffect(() => {
        if (!asset) {
            setDisclosureState(ROW_STATE_IDLE)
        } else if (busyIndex === index) { //if this row is busy, wait
            setDisclosureState(ROW_STATE_BUSY)
        } else if (lastActiveIndex === index) {//if this row is shown the undo or done button
            if (played) {
                setDisclosureState(ROW_STATE_UNDO_SHOWN)
            } else {
                setDisclosureState(ROW_STATE_DONE_SHOWN)
            }
        } else if (busyIndex >= 0) { //if some other row is busy, lock this row
            setDisclosureState(ROW_STATE_LOCKED)
        } else {
            setDisclosureState(ROW_STATE_IDLE)
        }
    }, [disclosureState, lastActiveIndex, busyIndex, index, played, asset])

    const nameAnimationVariations = {
        disclosureButtonVisible: {
            x: "2.75em"
        },
        disclosureBusyVisible: {
            x: "2.25em"
        },
        disclosureHidden: {
            x: "0em"
        }
    }

    const doneAnimationVariations = {
        doneVisible: {
            opacity: 1
        },
        doneHidden: {
            opacity: 0

        }
    }

    function clicked() {
        if (!asset || userType === 0) {
            return
        }
        switch (disclosureState) {
            case ROW_STATE_BUSY:
            case ROW_STATE_LOCKED:
                return
            case ROW_STATE_UNDO_SHOWN:
            case ROW_STATE_DONE_SHOWN:
                setLastActiveIndex(-1)
                break
            case ROW_STATE_IDLE:
                setLastActiveIndex(index)
                break
            default:
                setLastActiveIndex(-1)
                break
        }
    }
    function buttonClicked(e) {
        setLastActiveIndex(-1)
        setBusyIndex(parseInt(e.target.getAttribute("value")))
        toggleMarked(asset.id, !asset.played)
    }
    function disclosureShown() {
        return disclosureState > 1
    }
    function getNameAnimateState() {
        switch (disclosureState) {
            case ROW_STATE_BUSY:
                return "disclosureBusyVisible"
            case ROW_STATE_DONE_SHOWN:
            case ROW_STATE_UNDO_SHOWN:
                return "disclosureButtonVisible"
            default:
                return "disclosureHidden"
        }
    }
    return (
        <div onClick={clicked}
            className={"oneUp__table-row-content-warning" + (played ? " oneUp__table-row-content-played" : "")}>
            <span className="oneUp__tablerowbodyspan oneUp__table-body-name">
                <motion.div
                    className="oneUp__donebuttoncontainer"
                    animate={disclosureShown() ? "doneVisible" : "doneHidden"}
                    initial="doneHidden"
                    transition={{ duration: 0.25, ease: "easeOut", delay: 0 }}
                    variants={doneAnimationVariations}
                >
                    {disclosureState === ROW_STATE_DONE_SHOWN ?
                        <img className={"oneUp_doneButton"} value={index} src="images/doneButton.png" alt="done button" onClick={buttonClicked}></img>
                        :
                        disclosureState === ROW_STATE_UNDO_SHOWN ?
                            <img className={"oneUp_doneButton"} value={index} src="images/undoButton.png" alt="undo button" onClick={buttonClicked}></img>
                            :
                            busyIndex === index ?
                                <Spinner fadeIn={"none"} className="oneUp__spincontainer" name="folding-cube" color="yellow" />
                                :
                                null
                    }
                </motion.div>

                <motion.span
                    className="oneUp__table-body-nametext"
                    animate={getNameAnimateState()}
                    initial="disclosureHidden"
                    variants={nameAnimationVariations}
                    transition={{ type: 'spring', stiffness: 100, mass: 0.5, velocity: 5 }}
                >
                    {asset ? asset.title : ""}
                </motion.span>
            </span>
            <span className="oneUp__bodyseparator"></span>
            <span className="oneUp__tablerowbodyspan oneUp__table-lsm">
                {getLSM(asset)}
            </span>
            <span className="oneUp__bodyseparator"></span>
            <span className="oneUp__tablerowbodyspan oneUp__table-duration">
                {getDuration(asset)}
            </span>
            <span className="oneUp__bodyseparator"></span>
            <span className="oneUp__tablerowbodyspan oneUp__table-created">
                {getCreated(asset)}
            </span>
            <div className="oneUp__tablerowbodyspan-warning">
                <span className="oneUp__table-body-warning">
                    {/* <img className="oneUp__table-alert" alt="warning" src="/images/alert.png" /> */}
                    <span>{getWarning(asset)}</span>
                </span>
            </div>
        </div>
    )
}

function OneTableRowsUnwatched({ userType, unwatchedAssets, lastActiveIndex, setLastActiveIndex, busyIndex, setBusyIndex, toggleMarked }) {
    const fontSize = useSelector(state => state.fontSize)
    var el = []
    unwatchedAssets.forEach((asset, key) => {
        var warning = getWarning(asset)
        var canFitWarning = canFit(1, asset.title, warning, fontSize)
        el.push(<Draggable
            key={asset.id}
            draggableId={asset.id}
            index={key}
            isDragDisabled={userType > 0 ? false : true}
            type="unwatched"
        >
            {(provided, snapshot) => (
                <div ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps} className="oneUp__tablebody">
                    {warning && !canFitWarning ?
                        <LargeRowWarning
                            userType={userType}
                            asset={asset}
                            index={key}
                            lastActiveIndex={lastActiveIndex}
                            setLastActiveIndex={setLastActiveIndex}
                            busyIndex={busyIndex}
                            setBusyIndex={setBusyIndex}
                            toggleMarked={toggleMarked}
                            played={asset.played}
                        ></LargeRowWarning>
                        :
                        <LargeRow
                            userType={userType}
                            asset={asset}
                            index={key}
                            lastActiveIndex={lastActiveIndex}
                            setLastActiveIndex={setLastActiveIndex}
                            busyIndex={busyIndex}
                            setBusyIndex={setBusyIndex}
                            toggleMarked={toggleMarked}
                            played={asset.played}
                        ></LargeRow>
                    }
                </div>
            )}
        </Draggable>)
    })

    return el
}

function OneTableRowsEmpty({ count, lastActiveIndex, setLastActiveIndex, busyIndex, setBusyIndex }) {
    var el = []
    if (count > 0) {
        for (var i = 0; i < count; i++) {
            el.push(<Draggable
                key={"empty" + (count + i)}
                draggableId={"empty" + (count + i)}
                index={count + i}
                type="empty"
                isDragDisabled={true}
            >
                {(provided, snapshot) => (
                    <div ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps} className="oneUp__tablebody">
                    </div>
                )}
            </Draggable>)
        }
    }
    return el
}


function OneTableRowsWatched({ offsetIndex, watchedAssets, lastActiveIndex, setLastActiveIndex, busyIndex, setBusyIndex, toggleMarked }) {
    const fontSize = useSelector(state => state.fontSize)
    var el = []
    watchedAssets.forEach((asset, key) => {
        var warning = getWarning(asset)
        var canFitWarning = canFit(1, asset.title, warning, fontSize)
        el.push(<Draggable
            key={asset.id}
            draggableId={asset.id}
            index={key}
            type="watched"
            isDragDisabled={true}
        >
            {(provided, snapshot) => (
                <div ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps} className="oneUp__tablebody">
                    {warning && !canFitWarning ?
                        <LargeRowWarning asset={asset}
                            index={key + offsetIndex}
                            lastActiveIndex={lastActiveIndex}
                            setLastActiveIndex={setLastActiveIndex}
                            busyIndex={busyIndex}
                            setBusyIndex={setBusyIndex}
                            played={asset.played}
                            toggleMarked={toggleMarked}
                        ></LargeRowWarning>
                        :
                        <LargeRow asset={asset}
                            index={key + offsetIndex}
                            lastActiveIndex={lastActiveIndex}
                            setLastActiveIndex={setLastActiveIndex}
                            busyIndex={busyIndex}
                            setBusyIndex={setBusyIndex}
                            played={asset.played}
                            toggleMarked={toggleMarked}
                        ></LargeRow>
                    }
                </div>
            )}
        </Draggable>)
    })
    return el

}

function OneTable({ transformedBin, userType, bin1 }) {
    const ws = useContext(WebSocketContext)
    const dispatch = useDispatch()
    const [lastActiveIndex, setLastActiveIndex] = useState(-1)
    const [busyIndex, setBusyIndex] = useState(-1)
    const settings = useSelector(state => state.settings)
    const tempSettings = useSelector(state => state.tempSettings)
    const unwatchedAssets = useSelector(state => state.assetsUnwatched1)
    const watchedAssets = useSelector(state => state.assetsWatched1)
    const fontSize = useSelector(state => state.fontSize)
    // const [localFontSize] = useState(localStorage.getItem("fontSize") ? localStorage.getItem("fontSize") : "16.0" )
    const onBeforeCapture = useCallback(() => {
        dispatch(draggingStarted())
    }, [dispatch])
    useEffect(() => {
        document.body.style.fontSize = (fontSize)+"px";
    }, [])
    function getAdminLineNumber() {
        if (tempSettings && JSON.stringify(tempSettings) !== '{}') {
            return tempSettings[bin1.id].rowIndex
        } else {
            return settings[bin1.id] ? settings[bin1.id].rowIndex : 11
        }
    }
    const onDragEnd = useCallback((result) => {
        if (!result.source || !result.destination || result.source.index === result.destination.index) {
            return
        }
        let sourceIndex = result.source.index
        let destinationIndex = result.destination.index
        ws.reorderBin(bin1, 1, sourceIndex, destinationIndex)
        dispatch(draggingEnded())
        setLastActiveIndex(-1)
    }, [bin1, dispatch, ws])
    function toggleMarked(assetID, played) {
        ws.markAsPlayed(transformedBin.id, assetID, played, () => {
            setBusyIndex(-1)
        })

    }

    let fontSizeFloat = parseFloat(fontSize)
    let adminLineNumber = getAdminLineNumber()
    const dynaStyle = {
        // "marginTop": (3.5 * fontSizeFloat * adminLineNumber + (15 * adminLineNumber) - 8) + "px"
        "marginTop": (fontSizeFloat * 3.375 * adminLineNumber + fontSizeFloat * adminLineNumber + 2.0 * adminLineNumber) + "px"
    }
    return (
        <div className="oneUp__tablefeedcontainer">
            <div className="oneUp__tablefeed">
                <div className="oneUp__whiteseparator" style={dynaStyle}></div>
                <DragDropContext
                    onBeforeCapture={onBeforeCapture}
                    onDragEnd={onDragEnd}
                >
                    <Droppable droppableId="droppable_unwatched" type="unwatched">
                        {(provided, snapshot) => (
                            <div
                                className="oneUp__droppable"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >

                                <OneTableRowsUnwatched
                                    userType={userType}
                                    unwatchedAssets={unwatchedAssets}
                                    lastActiveIndex={lastActiveIndex}
                                    setLastActiveIndex={setLastActiveIndex}
                                    busyIndex={busyIndex}
                                    setBusyIndex={setBusyIndex}
                                    toggleMarked={toggleMarked}
                                />
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                    <Droppable
                        droppableId="droppable_empty"
                        type="empty">
                        {(provided, snapshot) => (
                            <div
                                className="oneUp__droppable"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                <OneTableRowsEmpty
                                    count={getAdminLineNumber() - unwatchedAssets.length}
                                    lastActiveIndex={lastActiveIndex}
                                    setLastActiveIndex={setLastActiveIndex}
                                    busyIndex={busyIndex}
                                    setBusyIndex={setBusyIndex} />

                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                    <Droppable
                        droppableId="droppable_watched"
                        type="watched">
                        {(provided, snapshot) => (
                            <div
                                className="oneUp__droppable"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                <OneTableRowsWatched
                                    offsetIndex={Math.max(getAdminLineNumber(), unwatchedAssets.length)}
                                    watchedAssets={watchedAssets}
                                    lastActiveIndex={lastActiveIndex}
                                    setLastActiveIndex={setLastActiveIndex}
                                    busyIndex={busyIndex}
                                    setBusyIndex={setBusyIndex}
                                    toggleMarked={toggleMarked}
                                />

                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </div>
    )
}
function OneUp({ user, logout }) {
    const status = useSelector(state => state.status)
    const bin1 = useSelector(state => state.bin1)
    const [transformedBin, setTransformedBin] = useState([])
    const bins = useSelector(state => state.bins)
    const [forceClose, setForceClose] = useState(false)
    const tempReorderTransform = useSelector(state => state.tempReorderTransform1)
    const ws = useContext(WebSocketContext)
    const [userType, setUserType] = useState(0)
    const dispatch = useDispatch()
    function wsDisconnect() {
        ws.disconnect()
    }
    function logoutFromBoard() {
        ws.disconnect()
        ws.connect()
        dispatch(reset())
        logout()
    }
    useEffect(() => {
        window.addEventListener('beforeunload', wsDisconnect)
        return () => window.removeEventListener('beforeunload', wsDisconnect)
    })
    useEffect(() => {
        if (!user || !user.roles) {
            return
        }
        if (user.roles.includes("dashboard-admin")) {
            setUserType(2)
        } else if (user.roles.includes("dashboard-manager")) {
            setUserType(1)
        } else {
            setUserType(0)
        }
    }, [user])
    useLayoutEffect(() => {
        const newTransformedBin = transformBin(bin1, tempReorderTransform)
        setTransformedBin(newTransformedBin)
        dispatch(transformBins1(newTransformedBin))

    }, [tempReorderTransform, bin1, dispatch])
    useEffect(() => {
        if (status === 2 && !bin1.id && bins.length > 0) { //if status is connected and no bin is selected and there are bins to select
            if (localStorage.getItem('binindex') && localStorage.getItem('binindex') < bins.length) {
                ws.selectedBinIDs([bins[localStorage.getItem('binindex')].id])
            } else {
                ws.selectedBinIDs([bins[0].id])
            }

        }

    }, [status, bin1, bins, ws])


    // useEffect(() => {
        // var timer = setTimeout(() => {
        //     console.log("Disconnecting and reconnecting")
        //     ws.disconnect()
        //     ws.connect()
        // }, 5000);
        // On first render, counter will be 0
        // The condition will be false and setTimeout() won't start
        // return () => clearTimeout(timer);

    // }, [bin1, ws]);

    function closeModal() {
        setForceClose(true)
    }
    const customStyles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.2)',

        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            background: 'clear',
            border: 0
        }
    }
    return (
        <div className="onePanel">
            <Modal
                isOpen={((status === 0 || bins.length === 0) && !forceClose)}
                // isOpen={false}
                onRequestClose={closeModal}
                onClick={closeModal}
                style={customStyles}
            >
                <div className="WaitingOverlay__panel" >
                    <div>
                        <div className="WaitingOverlay__loader"></div>
                        <div className="WaitingOverlay__loader-text">{bins.length === 0 ? "WAITING FOR BINS" : "RECONNECTING..."}</div>
                    </div>
                </div>
            </Modal>
            <div className="onePanel__panel">
                <Header user={user} userType={userType} logout={logoutFromBoard}></Header>
                <div className="oneUp__table-header">
                    <div className="oneUp__table-row-content">
                        <span className="oneUp_rowheaderspan">
                            NAME
                            </span>
                        <span className="oneUp_headerseparator"></span>
                        <span className="oneUp_rowheaderspan">
                            LSM ID
                            </span>
                        <span className="oneUp_headerseparator"></span>
                        <span className="oneUp_rowheaderspan">
                            DURATION
                            </span>
                        <span className="oneUp_headerseparator"></span>
                        <span className="oneUp_rowheaderspan">
                            CREATED
                            </span>
                    </div>
                </div>
                <OneTable
                    transformedBin={transformedBin}
                    bin1={bin1}
                    userType={userType}>
                </OneTable>
            </div>
        </div>
    )
}

export default OneUp;