import React from "react"
import Timecode from "smpte-timecode"
import { format } from 'date-fns'
const timecodeRegex = /\d\d:\d\d:\d\d/gm
export function Dot({status}) {
    switch(status) {
        case 1:
            return <div className="dot yellowDot"></div>
        case 2:
            return <div className="dot greenDot"></div>
        default:
            return <div className="dot redDot"></div>
    }
}
export function getLSM(asset) {
    if(!asset) {
        return ""
    }
    try {
        const profile = asset.profiles.find(e => {
            return e.id === "evsserverasset"
        })
        return profile && profile.values.LsmId ? profile.values.LsmId : ""
    } catch (e) {
        console.log("err: ", e)
    }
}
export function getDuration(asset) {
    if(!asset) {
        return ""
    }
    try {
        const profile = asset.profiles.find(e => {
            return e.id === "evsserverasset"
        })
        const inTimecodeString = profile.values.LinearInterestTimecodeBounds.in
        const outTimecodeString = profile.values.LinearInterestTimecodeBounds.out
        const inResults = inTimecodeString.match(timecodeRegex)
        const outResults = outTimecodeString.match(timecodeRegex)

        if (inResults.length > 0 && outResults.length > 0) {
            let inTimecode = Timecode(inResults[0]+';00')
            let outTimecode = Timecode(outResults[0]+';00')
            outTimecode.subtract(inTimecode)
            return outTimecode.toString().substring(0, 8)
        } else {
            return "00:00:00"
        }
    } catch (e) {
        console.log("err:", e);
    }
    
}

export function getCreated(asset) {
    if(!asset) {
        return ""
    }
    try {
        
        var d = new Date(asset.creationDate)
        return format(d, 'HH:MM:ss')
    } catch (e) {
        console.log("err:", e);
    }
    
}
export function transformBin(bin, transforms) {
    if(JSON.stringify(bin) === '{}' || !transforms || transforms.length === 0) {
        return bin
    } else {
        const copyBin = Object.assign({},bin)
        transforms.forEach((transform, index) => {
            let sourceAssetIndex
            copyBin.mediaAssets.find((e, index) => {
                if(transform.sourceID === e.id) {
                    sourceAssetIndex = index
                    return e
                } 
                return undefined
            })
            if(sourceAssetIndex===null) {
                return
            } else {
                const tempAsset = copyBin.mediaAssets[sourceAssetIndex]
                copyBin.mediaAssets.splice(sourceAssetIndex, 1)
                copyBin.mediaAssets.splice(transform.sourceIDInsertTo, 0, tempAsset)
            }  
        })
        return copyBin
    }
}
export function getWarning(asset) {
    if(!asset) {
        return null
    }
    try {
        const warningProfile = asset.profiles.find(e => {
            return e.title === "E Metadata"
        })

        return warningProfile ? warningProfile.values.Warning : null
    } catch (e) {
        console.log("err:", e);
    }
}
export function canFit(viewType, binName, warningText, textSize) {
    
    if(warningText) {
        return (warningText.length + binName.length < Math.floor(20*(24.0/textSize)))
    } else 
        return true
}