import React, { useState, useEffect, useContext, useCallback, useLayoutEffect } from "react"
import { Dot, getDuration, getLSM, transformBin, getWarning, getCreated } from "./Helpers"
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { WebSocketContext } from './WebSocket';
import "./TwoUp.css";
import Popover from 'react-tiny-popover'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { motion } from "framer-motion"
import {
    draggingStarted,
    draggingEnded,
    fontSizeUpdated,
    transformBins1,
    transformBins2,
    reset,
    board2BinSelected,
    board1BinSelected
} from './actions'

import "./WaitingOverlay.css";
import Modal from 'react-modal';
import Spinner from "react-spinkit"
// import qs from 'qs'
import {isFloat, isInt} from 'validator';
import { VERSION } from './config.js'
const ROW_STATE_BUSY = 4
const ROW_STATE_UNDO_SHOWN = 3
const ROW_STATE_DONE_SHOWN = 2
const ROW_STATE_LOCKED = 1
const ROW_STATE_IDLE = 0
function Preferences({user, userType, exit, logout }) {
    // const [fontSize, setFontSize] = useState("16.0")    
    const fontSize = useSelector(state => state.fontSize)
    const dispatch = useDispatch()
    const bin1 = useSelector(state => state.bin1)
    const bin2 = useSelector(state => state.bin2)
    const bin1ID = useSelector(state => state.bin1ID)
    const bin2ID = useSelector(state => state.bin2ID)
    const settings = useSelector(state => state.settings)
    // const prevSettings = usePrevious(settings)
    // function usePrevious(value) {
    //     const ref = useRef();
    //     useEffect(() => {
    //       ref.current = value;
    //     });
    //     return ref.current;
    // }
    const ws = useContext(WebSocketContext)
    const [separatorIndex1, setSeparatorIndex1] = useState(11)
    const [separatorIndex2, setSeparatorIndex2] = useState(11)
    

    function fontSizeCheckInput(e) {
        if(isFloat(e.target.value) || e.target.value === "") {
            dispatch(fontSizeUpdated(e.target.value))
        } else {
            return
        }
    }
    function changeFontSize() {
        if(parseFloat(fontSize) < 5.0 ) {
            // setFontSize("10.0")
            dispatch(fontSizeUpdated("10.0"))
            document.body.style.fontSize = "10.0px";
        } else if(parseFloat(fontSize) > 24.0) {
            // setFontSize("24.0")
            dispatch(fontSizeUpdated("24.0"))
            document.body.style.fontSize = "24.0px";
        } else {
            document.body.style.fontSize = fontSize + "px";
        }
    }
    function fontSizeOnKeyPress(e) {
        if(e.key === "Enter") {
            changeFontSize()
        }
    }
    function fontSizePlus() {
        // setFontSize(Math.round((parseFloat(fontSize) + 0.1) * 10)/10 + "")
        dispatch(fontSizeUpdated(Math.round((parseFloat(fontSize) + 0.1) * 10)/10 + ""))
        changeFontSize()
    }
    function fontSizeMinus() {
        // setFontSize(Math.round((parseFloat(fontSize) - 0.1) * 10)/10 + "")
        dispatch(fontSizeUpdated(Math.round((parseFloat(fontSize) - 0.1) * 10)/10 + ""))
        changeFontSize()
    }
    function separator1CheckInput(e) {
        if(isInt(e.target.value) || e.target.value === "") {
            setSeparatorIndex1(parseInt(e.target.value))
        } else {
            return
        }
    }
    function separator1Plus() {
        let newIndex = separatorIndex1 + 1
        ws.adminMarkerUpdated(bin1, newIndex)
        setSeparatorIndex1(newIndex)
    }
    function separator1Minus() {
        let newIndex = Math.max(separatorIndex1 - 1, 0)
        ws.adminMarkerUpdated(bin1, newIndex)
        setSeparatorIndex1(newIndex)
    }
    function separator2CheckInput(e) {
        if(isInt(e.target.value) || e.target.value === "") {
            setSeparatorIndex2(parseInt(e.target.value))
        } else {
            return
        }
    }
    function separator2Plus() {
        let newIndex = separatorIndex2 + 1
        ws.adminMarkerUpdated(bin2, newIndex)
        setSeparatorIndex2(newIndex)
    }
    function separator2Minus() {
        let newIndex = Math.max(separatorIndex2 - 1, 0)
        ws.adminMarkerUpdated(bin2, newIndex)
        setSeparatorIndex2(newIndex)
    }
    useEffect(() => {
        if(settings[bin1.id]) {
            if (typeof settings[bin1.id].rowIndex === "string" && isInt(settings[bin1.id].rowIndex)) {
                setSeparatorIndex1(parseInt(settings[bin1.id].rowIndex))
            } else {
                setSeparatorIndex1(settings[bin1.id].rowIndex)
            }
            
        }
    }, [settings, bin1])
    useEffect(() => {
        if(settings[bin2.id]) {
            if (typeof settings[bin2.id].rowIndex === "string" && isInt(settings[bin2.id].rowIndex)) {
                setSeparatorIndex2(parseInt(settings[bin2.id].rowIndex))
            } else {
                setSeparatorIndex2(settings[bin2.id].rowIndex)
            }
            
        }
    }, [settings, bin2])
    function getInfo() {
        return `${user.name} (${user.roles.join(", ")}) ${VERSION}`
    }
    return (
        <div className="twoUp__preferencespopovercontainer">
            <div className="twoUp__preferencespopover">
                {/* <div className="twoUp_settingcontainer"> */}
                    {/* <div className="twoUp_prefsettingtext">View Type</div> */}
                    {/* <div className="twoUp_viewtypecontainersingle">{standardUser ? "SINGLE" : "ADMIN"}<img className="dropdown_small" alt="dropdown" src="images/dropdown_small.png" /></div> */}
                    {/* <DropdownExampleDropdown></DropdownExampleDropdown> */}
                {/* </div> */}
                <div className="twoUp_settingcontainer">
                    <div className="twoUp_prefsettingtext">Row Size</div>
                    <div className="twoUp__rowsizecontrolscontainer">
                        <img onClick={fontSizeMinus} className="twoUp__rowsizeminuscontainer" alt="minus" src="images/minus.png" />
                    {/*<span className="twoUp__rowsizevalue">9</span>*/}
                        <input className="twoUp__fontsizeinput" value={fontSize} onChange={fontSizeCheckInput} onKeyDown={fontSizeOnKeyPress}></input>
                        <img onClick={fontSizePlus} alt="plus" src="images/plus.png" className="twoUp__rowsizepluscontainer" />
                    </div>
                </div>
                <div className="twoUp_settingcontainer">
                    <div className="twoUp_prefsettingtext">Separater 1 Index</div>
                    <div className="twoUp__rowsizecontrolscontainer">
                        <img onClick={separator1Minus} className="twoUp__rowsizeminuscontainer" alt="minus" src="images/minus.png" style={userType === 0 ? {display: "none"} : null}/>
                        <input disabled className="twoUp__fontsizeinput" value={separatorIndex1 ? separatorIndex1 : 11} onChange={separator1CheckInput}></input>
                        <img onClick={separator1Plus} alt="plus" src="images/plus.png" style={userType === 0 ? {display: "none"} : null} className="twoUp__rowsizepluscontainer" />
                    </div>
                </div>
                <div className="twoUp_settingcontainer">
                    <div className="twoUp_prefsettingtext">Separater 2 Index</div>
                    <div className="twoUp__rowsizecontrolscontainer">
                        <img onClick={separator2Minus} className="twoUp__rowsizeminuscontainer" alt="minus" src="images/minus.png" style={userType === 0 ? {display: "none"} : null}/>
                        <input disabled className="twoUp__fontsizeinput" value={separatorIndex1 ? separatorIndex1 : 11} onChange={separator2CheckInput}></input>
                        <img onClick={separator2Plus} alt="plus" src="images/plus.png" style={userType === 0 ? {display: "none"} : null} className="twoUp__rowsizepluscontainer" />
                    </div>
                </div>
                <div className="twoUp_settingcontainer">
                    <div className="twoUp_prefsettingtext">View Type</div>
                    <div className="twoUp__rowsizecontrolscontainer">
                        <Link to="/oneup">One Panel</Link>
                    </div>
                </div>
                <div className="twoUp_settingcontainer">
                    <div className="twoUp_prefsettingtext twoUp__prefinfo">{getInfo()}</div>
                    <div className="twoUp__rowsizecontrolscontainer">
                        <div className="twoUp_logout" onClick={logout} ><img alt="logout" src="images/logout.png"/></div>
                    </div>
                </div>
            </div>
        </div>
    )
}
function BinList({ exit, binNumber }) {
    const bins = useSelector(state => state.bins)
    const bin1ID = useSelector(state => state.bin1ID)
    const bin2ID = useSelector(state => state.bin2ID)
    const ws = useContext(WebSocketContext);
    function selectedBin(e) {
        exit()
        if(e.currentTarget.getAttribute("binindex")) {
            localStorage.setItem('binindex', e.currentTarget.getAttribute("binindex"))
        }        
        if(binNumber==1) {
            var binsSelected = [];
            binsSelected.push(bins[e.currentTarget.getAttribute("binindex")].id)
            if(bin2ID) {
                binsSelected.push(bin2ID)
            }
            ws.selectedBinIDs(binsSelected)
        } else {
            var binsSelected = [];
            if(bin1ID) {
                binsSelected.push(bin1ID)
            }
            binsSelected.push(bins[e.currentTarget.getAttribute("binindex")].id)
            ws.selectedBinIDs(binsSelected)
        }
        
    }
    return (
        <div className="twoUp__binpopover">
            <div className="twoUp__binpopover-container">
                {bins.map((item, key) =>
                    <div onClick={selectedBin} className="twoUp__binpopovername" key={item.id} binindex={key} binid={item.id}><span className="twoUp__binpopovername-text">{item.title}</span></div>
                )}
            </div>
        </div>
    )
}
function Header({ user, userType, logout, showGear, binNumber}) {
    const bin1 = useSelector(state => state.bin1)
    const bin2 = useSelector(state => state.bin2)

    
    const [isBinPopoverOpen, setIsBinPopoverOpen] = useState(false)
    const [isPreferencesPopoverOpen, setIsPreferencesPopoverOpen] = useState(false)
    const status = useSelector(state => state.status)
    function fullscreenMode() {
        var elem = document.documentElement
        if (elem.requestFullscreen) {
            elem.requestFullscreen()
          } else if (elem.mozRequestFullScreen) { 
            elem.mozRequestFullScreen()
          } else if (elem.webkitRequestFullscreen) { 
            elem.webkitRequestFullscreen()
          } else if (elem.msRequestFullscreen) { 
            elem.msRequestFullscreen()
          }
    }
    return <div className="twoUp__header">
        <Dot status={status} />
        <div onClick={fullscreenMode} className="twoUp__fullscreenbutton">
            <img alt="fullscreen button" src="images/fullscreen.png"/>
        </div>
        <Popover
            transitionDuration={0.000001}
            isOpen={isBinPopoverOpen}
            position={'bottom'} // preferred position
            onClickOutside={() => setIsBinPopoverOpen(false)}
            content={<BinList exit={() => { setIsBinPopoverOpen(false) }} binNumber={binNumber}></BinList>}>
            {binNumber == 1 ?
            <div onClick={() => setIsBinPopoverOpen(true)} className="twoUp__header-text">{bin1 && bin1.title ? bin1.title : ""}<img className="twoUp__dropdown" alt="change bin" src="images/dropdown.png" /></div>
            :
            <div onClick={() => setIsBinPopoverOpen(true)} className="twoUp__header-text">{bin2 && bin2.title ? bin2.title : ""}<img className="twoUp__dropdown" alt="change bin" src="images/dropdown.png" /></div>
            }
        </Popover>
        
        <Popover
            isOpen={isPreferencesPopoverOpen}
            position={'bottom'} // preferred position
            onClickOutside={() => setIsPreferencesPopoverOpen(false)}
            content={<Preferences user={user} userType={userType} logout={logout} exit={() => { setIsPreferencesPopoverOpen(false) }}></Preferences>}>
            {showGear ? <img onClick={() => setIsPreferencesPopoverOpen(!isPreferencesPopoverOpen)} className="twoUp__gear" alt="preferences" src="images/gear.png" /> : <div></div>}
        </Popover>
    </div>
}
function LargeRow({userType, asset, index, lastActiveIndex, setLastActiveIndex, busyIndex, setBusyIndex, toggleMarked, played}) {
    const [disclosureState, setDisclosureState] = useState(0)
    // const settings = useSelector(state => state.settings)
    // const tempSettings = useSelector(state => state.tempSettings)
    useEffect(() => {
        if(!asset) {
            setDisclosureState(ROW_STATE_IDLE)
        } else if(busyIndex === index) { //if this row is busy, wait
            setDisclosureState(ROW_STATE_BUSY)
        } else if(lastActiveIndex === index) {//if this row is shown the undo or done button
            if(played) {
                setDisclosureState(ROW_STATE_UNDO_SHOWN)
            } else {
                setDisclosureState(ROW_STATE_DONE_SHOWN)
            }
            
        } else if(busyIndex >= 0) { //if some other row is busy, lock this row
            setDisclosureState(ROW_STATE_LOCKED)
        } else {
            setDisclosureState(ROW_STATE_IDLE)
        }
    }, [disclosureState, lastActiveIndex, busyIndex, index, played, asset])

    const nameAnimationVariations = {
        disclosureButtonVisible: {
            x:"2.75em"
        },
        disclosureBusyVisible: {
            x:"2.25em"
        },
        disclosureHidden: {
            x:"0em"
        }
    }

    const doneAnimationVariations = {
        doneVisible: {
            opacity:1
        },
        doneHidden: {
            opacity:0
            
        }
    }

    function clicked() {
        if(!asset || userType === 0) {
            return
        }
        switch(disclosureState) {
            case ROW_STATE_BUSY:
            case ROW_STATE_LOCKED:
                return
            case ROW_STATE_UNDO_SHOWN:
            case ROW_STATE_DONE_SHOWN:
                setLastActiveIndex(-1)
                break
            case ROW_STATE_IDLE:
                setLastActiveIndex(index)
                break
            default:
                setLastActiveIndex(-1)
                break
        }
    }
    function buttonClicked(e) {
        setLastActiveIndex(-1)
        setBusyIndex(parseInt(e.target.getAttribute("value")))
        toggleMarked(asset.id, !asset.played)
    }
    function disclosureShown() {
        return disclosureState > 1
    }
    function getNameAnimateState() {
        switch(disclosureState) {
            case ROW_STATE_BUSY:
                return "disclosureBusyVisible"
            case ROW_STATE_DONE_SHOWN:
            case ROW_STATE_UNDO_SHOWN:
                return "disclosureButtonVisible"
            default:
                return "disclosureHidden"
        }
    }
    return (
        <div onClick={clicked}
            className={"twoUp__table-row-content" + (played ? " twoUp__table-row-content-played": "" )}>
            <span className="twoUp__tablerowbodyspan twoUp__table-body-name">
                <motion.div 
                    className="twoUp__donebuttoncontainer"
                    animate={disclosureShown() ? "doneVisible" : "doneHidden"}
                    initial="doneHidden"
                    transition={{ duration: 0.25, ease:"easeOut", delay:0 }}
                    variants={doneAnimationVariations}
                    >
                    { disclosureState === ROW_STATE_DONE_SHOWN ? 
                        <img className={"twoUp_doneButton"} value={index} src="images/doneButton.png" alt="done button" onClick={buttonClicked}></img>
                        :
                        disclosureState === ROW_STATE_UNDO_SHOWN ?
                        <img className={"twoUp_doneButton"} value={index} src="images/undoButton.png" alt="undo button" onClick={buttonClicked}></img>
                        :
                        busyIndex === index ? 
                        <Spinner fadeIn={"none"} className="twoUp__spincontainer" name="folding-cube" color="yellow" />
                        :
                        null
                    }
                </motion.div>

                <motion.span 
                    className="twoUp__table-body-nametext"
                    animate={getNameAnimateState()}
                    initial="disclosureHidden"
                    variants={nameAnimationVariations}
                    transition={{ type: 'spring', stiffness:100, mass:0.5, velocity: 5 }}
                    >
                    {asset ? asset.title : "test"}
                </motion.span>
                {/* TODO: Possible optimization here with getWarning() */}
                
                {/* {getWarning(asset) ? <span className="twoUp__table-body-warning"><img className="twoUp__table-alert" alt="warning" src="/images/alert.png" /><span>{getWarning(asset)}</span></span>: <span></span>} */}
                
            </span>
            <span className="twoUp__bodyseparator"></span>
            <span className="twoUp__tablerowbodyspan twoUp__table-lsm">
                {getLSM(asset)}
            </span>
            <span className="twoUp__bodyseparator"></span>
            <span className="twoUp__tablerowbodyspan twoUp__table-duration">
                {getDuration(asset)}
            </span>
        </div>
    )
}
function LargeRowWarning({userType, asset, index, lastActiveIndex, setLastActiveIndex, busyIndex, setBusyIndex, toggleMarked, played}) {
    const [disclosureState, setDisclosureState] = useState(0)
    // const settings = useSelector(state => state.settings)
    // const tempSettings = useSelector(state => state.tempSettings)
    useEffect(() => {
        if(!asset) {
            setDisclosureState(ROW_STATE_IDLE)
        } else if(busyIndex === index) { //if this row is busy, wait
            setDisclosureState(ROW_STATE_BUSY)
        } else if(lastActiveIndex === index) {//if this row is shown the undo or done button
            if(played) {
                setDisclosureState(ROW_STATE_UNDO_SHOWN)
            } else {
                setDisclosureState(ROW_STATE_DONE_SHOWN)
            }
            
        } else if(busyIndex >= 0) { //if some other row is busy, lock this row
            setDisclosureState(ROW_STATE_LOCKED)
        } else {
            setDisclosureState(ROW_STATE_IDLE)
        }
    }, [disclosureState, lastActiveIndex, busyIndex, index, played, asset])

    const nameAnimationVariations = {
        disclosureButtonVisible: {
            x:"2.75em"
        },
        disclosureBusyVisible: {
            x:"2.25em"
        },
        disclosureHidden: {
            x:"0em"
        }
    }

    const doneAnimationVariations = {
        doneVisible: {
            opacity:1
        },
        doneHidden: {
            opacity:0
            
        }
    }

    function clicked() {
        if(!asset || userType === 0) {
            return
        }
        switch(disclosureState) {
            case ROW_STATE_BUSY:
            case ROW_STATE_LOCKED:
                return
            case ROW_STATE_UNDO_SHOWN:
            case ROW_STATE_DONE_SHOWN:
                setLastActiveIndex(-1)
                break
            case ROW_STATE_IDLE:
                setLastActiveIndex(index)
                break
            default:
                setLastActiveIndex(-1)
                break
        }
    }
    function buttonClicked(e) {
        setLastActiveIndex(-1)
        setBusyIndex(parseInt(e.target.getAttribute("value")))
        toggleMarked(asset.id, !asset.played)
    }
    function disclosureShown() {
        return disclosureState > 1
    }
    function getNameAnimateState() {
        switch(disclosureState) {
            case ROW_STATE_BUSY:
                return "disclosureBusyVisible"
            case ROW_STATE_DONE_SHOWN:
            case ROW_STATE_UNDO_SHOWN:
                return "disclosureButtonVisible"
            default:
                return "disclosureHidden"
        }
    }
    return (
        <div onClick={clicked}
            className={"twoUp__table-row-content-warning" + (played ? " twoUp__table-row-content-played": "" )}>
            <span className="twoUp__tablerowbodyspan twoUp__table-body-name">
                <motion.div 
                    className="twoUp__donebuttoncontainer"
                    animate={disclosureShown() ? "doneVisible" : "doneHidden"}
                    initial="doneHidden"
                    transition={{ duration: 0.25, ease:"easeOut", delay:0 }}
                    variants={doneAnimationVariations}
                    >
                    { disclosureState === ROW_STATE_DONE_SHOWN ? 
                        <img className={"twoUp_doneButton"} value={index} src="images/doneButton.png" alt="done button" onClick={buttonClicked}></img>
                        :
                        disclosureState === ROW_STATE_UNDO_SHOWN ?
                        <img className={"twoUp_doneButton"} value={index} src="images/undoButton.png" alt="undo button" onClick={buttonClicked}></img>
                        :
                        busyIndex === index ? 
                        <Spinner fadeIn={"none"} className="twoUp__spincontainer" name="folding-cube" color="yellow" />
                        :
                        null
                    }
                </motion.div>

                <motion.span 
                    className="twoUp__table-body-nametext"
                    animate={getNameAnimateState()}
                    initial="disclosureHidden"
                    variants={nameAnimationVariations}
                    transition={{ type: 'spring', stiffness:100, mass:0.5, velocity: 5 }}
                    >
                    {asset ? asset.title : "test"}
                </motion.span>
                {/* TODO: Possible optimization here with getWarning() */}
                
                
            </span>
            <span className="twoUp__bodyseparator"></span>
            <span className="twoUp__tablerowbodyspan twoUp__table-lsm">
                {getLSM(asset) }
            </span>
            <span className="twoUp__bodyseparator"></span>
            <span className="twoUp__tablerowbodyspan twoUp__table-duration">
                {getDuration(asset)}
            </span>
            <div className="twoUp__tablerowbodyspan-warning">
                <span className="twoUp__table-body-warning">
                    <img className="twoUp__table-alert" alt="warning" src="/images/alert.png" />
                    <span>{getWarning(asset)}</span>
                </span>
            </div>
        </div>
    )
}
// function AdminLine({standardUser, index}) {
//     return (
//         <Draggable isDragDisabled={false} draggableId="adminLine" index={index}>
//             {(provided, snapshot) => (
//                 <div ref={provided.innerRef}
//                 {...provided.draggableProps}
//                 {...provided.dragHandleProps} className="twoUp__adminlinecontainer">
//                     <div className="twoUp__adminline"></div>
//                 </div>
//             )}
//         </Draggable>
//     )
// }

function OneTableRowsUnwatched({userType, unwatchedAssets, lastActiveIndex, setLastActiveIndex, busyIndex, setBusyIndex, toggleMarked}) {
    // const bin1 = useSelector(state => state.bin1)
    var el = []
    unwatchedAssets.forEach((asset, key) => {
        el.push(<Draggable
            key={asset.id}
            draggableId={asset.id}
            index={key}
            isDragDisabled={userType > 0 ? false : true}
            type="unwatched"
            >
            {(provided, snapshot) => (
                <div ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps} className="twoUp__tablebody">
                    {getWarning(asset) ? 
                        <LargeRowWarning
                        userType={userType}
                        asset={asset}
                        index={key}
                        lastActiveIndex={lastActiveIndex}
                        setLastActiveIndex={setLastActiveIndex}
                        busyIndex={busyIndex}
                        setBusyIndex={setBusyIndex}
                        toggleMarked={toggleMarked}
                        played={asset.played}
                        ></LargeRowWarning>
                        :
                        <LargeRow
                        userType={userType}
                        asset={asset}
                        index={key}
                        lastActiveIndex={lastActiveIndex}
                        setLastActiveIndex={setLastActiveIndex}
                        busyIndex={busyIndex}
                        setBusyIndex={setBusyIndex}
                        toggleMarked={toggleMarked}
                        played={asset.played}
                        ></LargeRow>
                    }
                </div>
            )}
        </Draggable>)
    })
    
    return el
}

function OneTableRowsEmpty({count, lastActiveIndex, setLastActiveIndex, busyIndex, setBusyIndex}) {
    // const settings = useSelector(state => state.settings)
    // const tempSettings = useSelector(state => state.tempSettings)
    // const bin1 = useSelector(state => state.bin1)
    var el = []
    if(count > 0) {
        for(var i = 0; i < count; i++) {
            el.push(<Draggable
                key={"empty" + (count + i)}
                draggableId={"empty" + (count + i)}
                index={count + i}
                type="empty" 
                isDragDisabled={true}
                >
                {(provided, snapshot) => (
                    <div ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps} className="twoUp__tablebody">
                    </div>
                )}
            </Draggable>)
        }
    }
    return el
}

function OneTableRowsWatched({offsetIndex, watchedAssets, lastActiveIndex, setLastActiveIndex, busyIndex, setBusyIndex, toggleMarked}) {
    var el = []
    watchedAssets.forEach((asset, key) => {
        el.push(<Draggable
            key={asset.id}
            draggableId={asset.id}
            index={key}
            type="watched"
            isDragDisabled={true}
            >
            {(provided, snapshot) => (
                <div ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps} className="twoUp__tablebody">
                    {getWarning(asset) ? 
                        <LargeRowWarning asset={asset}
                        index={key + offsetIndex}
                        lastActiveIndex={lastActiveIndex}
                        setLastActiveIndex={setLastActiveIndex}
                        busyIndex={busyIndex}
                        setBusyIndex={setBusyIndex}
                        played={asset.played}
                        toggleMarked={toggleMarked}
                        ></LargeRowWarning>
                        :
                        <LargeRow asset={asset}
                        index={key + offsetIndex}
                        lastActiveIndex={lastActiveIndex}
                        setLastActiveIndex={setLastActiveIndex}
                        busyIndex={busyIndex}
                        setBusyIndex={setBusyIndex}
                        played={asset.played}
                        toggleMarked={toggleMarked}
                        ></LargeRow>
                    }
                </div>
            )}
        </Draggable>)
    })
    return el

}

function OneTable({ transformedBin, userType, bin, unwatchedAssets, watchedAssets }) {
    const ws = useContext(WebSocketContext)
    const dispatch = useDispatch()
    const [lastActiveIndex, setLastActiveIndex] = useState(-1)
    const [busyIndex, setBusyIndex] = useState(-1)
    const settings = useSelector(state => state.settings)
    const tempSettings = useSelector(state => state.tempSettings)
    // const unwatched1Assets = useSelector(state => state.assetsUnwatched1)
    // const watched1Assets = useSelector(state => state.assetsWatched1)
    // const unwatched2Assets = useSelector(state => state.assetsUnwatched2)
    // const watched2Assets = useSelector(state => state.assetsWatched2)
    const fontSize = useSelector(state => state.fontSize)
    const onBeforeCapture = useCallback(() => {
        dispatch(draggingStarted())
    }, [dispatch])
    function getAdminLineNumber() {
        if(!bin) {
            return 11
        }
        if(tempSettings && JSON.stringify(tempSettings) !== '{}') {
            return tempSettings[bin.id].rowIndex
        } else {
            return settings[bin.id] ? settings[bin.id].rowIndex : 11
        }
    }
    const onDragEnd = useCallback((result) => {
        if (!result.source || !result.destination || result.source.index === result.destination.index) {
            return
        }
        let sourceIndex = result.source.index
        let destinationIndex = result.destination.index
        ws.reorderBin(bin, 1, sourceIndex, destinationIndex)
        dispatch(draggingEnded())
        setLastActiveIndex(-1)
    },[bin, dispatch, ws])
    function toggleMarked(assetID, played) {
        ws.markAsPlayed(transformedBin.id, assetID, played, () => {
            setBusyIndex(-1)
        })
        
    }
    
    // if (transformedBin.mediaAssets && transformedBin.mediaAssets.length > 0) {
        let fontSizeFloat = parseFloat(fontSize)
        let adminLineNumber = getAdminLineNumber()
        const dynaStyle = {
            "marginTop": (3.5 * fontSizeFloat * adminLineNumber + (15 * adminLineNumber) - 8) + "px"
            // "margin-top": 0
        }
        return (
            <div className="twoUp__tablefeedcontainer">
                <div className="twoUp__tablefeed">
                    <div className="twoUp__whiteseparator" style={dynaStyle}></div>
                    <DragDropContext
                        onBeforeCapture={onBeforeCapture}
                        // onBeforeDragStart={onBeforeDragStart}
                        // onDragStart={onDragStart}
                        // onDragUpdate={onDragUpdate}
                        onDragEnd={onDragEnd}
                        >
                    <Droppable droppableId="droppable_unwatched" type="unwatched">
                        {(provided, snapshot) => (
                            <div
                                className="twoUp__droppable"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                
                                <OneTableRowsUnwatched
                                userType={userType}
                                unwatchedAssets={unwatchedAssets}
                                lastActiveIndex={lastActiveIndex}
                                setLastActiveIndex={setLastActiveIndex}
                                busyIndex={busyIndex}
                                setBusyIndex={setBusyIndex}
                                toggleMarked={toggleMarked}
                                />
                                {provided.placeholder}
                            </div>    
                        )}
                        </Droppable>
                        <Droppable 
                            droppableId="droppable_empty"
                            type="empty">
                        {(provided, snapshot) => (
                            <div
                                className="twoUp__droppable"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                <OneTableRowsEmpty
                                count={getAdminLineNumber() - unwatchedAssets.length}
                                lastActiveIndex={lastActiveIndex}
                                setLastActiveIndex={setLastActiveIndex}
                                busyIndex={busyIndex}
                                setBusyIndex={setBusyIndex}/>

                                {provided.placeholder}
                            </div>    
                        )}
                        </Droppable>
                        <Droppable
                            droppableId="droppable_watched"
                            type="watched">
                        {(provided, snapshot) => (
                            <div
                                className="twoUp__droppable"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                <OneTableRowsWatched
                                offsetIndex={Math.max(getAdminLineNumber(), unwatchedAssets.length)}
                                watchedAssets={watchedAssets}
                                lastActiveIndex={lastActiveIndex}
                                setLastActiveIndex={setLastActiveIndex}
                                busyIndex={busyIndex}
                                setBusyIndex={setBusyIndex}
                                toggleMarked={toggleMarked}
                                />
                                
                                {provided.placeholder}
                            </div>    
                        )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>
        )
    // } else {
    //     return (<div className="twoUp__tablefeed-empty"></div>)
    // }
}
function TwoUp({user, logout}) {
    // const location = useLocation()
    const status = useSelector(state => state.status)
    const bin1 = useSelector(state => state.bin1)
    const bin2 = useSelector(state => state.bin2)
    const [transformedBin1, setTransformedBin1] = useState([])
    const [transformedBin2, setTransformedBin2] = useState([])
    const bins = useSelector(state => state.bins)
    const [forceClose, setForceClose] = useState(false)
    const tempReorderTransform1 = useSelector(state => state.tempReorderTransform1)
    const tempReorderTransform2 = useSelector(state => state.tempReorderTransform2)

    const unwatched1Assets = useSelector(state => state.assetsUnwatched1)
    const watched1Assets = useSelector(state => state.assetsWatched1)
    const unwatched2Assets = useSelector(state => state.assetsUnwatched2)
    const watched2Assets = useSelector(state => state.assetsWatched2)

    const ws = useContext(WebSocketContext)
    const [userType, setUserType] = useState(0)
    // const [standardUser, setStandardUser] = useState(false)
    const dispatch = useDispatch()
    function wsDisconnect() {
        ws.disconnect()
    }
    function logoutFromBoard() {
        ws.disconnect()
        ws.connect()
        dispatch(reset())
        logout()
    }
    // useEffect(() => {
    //     if (qs.parse(location.search, { ignoreQueryPrefix: true }).user) {
    //         setStandardUser(true)
    //     }
    // }, [location])
    useEffect(() => {
        window.addEventListener('beforeunload', wsDisconnect)
        return () => window.removeEventListener('beforeunload', wsDisconnect)
    })
    useEffect(() => {
        if(!user || !user.roles) {
            return
        }
        if(user.roles.includes("dashboard-admin")) {
            setUserType(2)
        } else if (user.roles.includes("dashboard-manager")) {
            setUserType(1)
        } else {
            setUserType(0)
        }
    }, [user])
    useLayoutEffect(() => {
        const newTransformedBin = transformBin(bin1, tempReorderTransform1)
        setTransformedBin1(newTransformedBin)
        dispatch(transformBins1(newTransformedBin))

    }, [tempReorderTransform1, bin1, dispatch])

    useLayoutEffect(() => {
        const newTransformedBin = transformBin(bin2, tempReorderTransform2)
        setTransformedBin2(newTransformedBin)
        dispatch(transformBins2(newTransformedBin))

    }, [tempReorderTransform2, bin2, dispatch])
    useEffect(() => {
        if (status === 2 && !bin1.id && bins.length > 0) { //if status is connected and no bin is selected and there are bins to select
            if(localStorage.getItem('binindex') && localStorage.getItem('binindex') < bins.length) {
                ws.selectedBinIDs([bins[localStorage.getItem('binindex')].id])
            } else {
                ws.selectedBinIDs([bins[0].id])
            }
            
        }

    }, [status, bin1, bins, ws])


    useEffect( () => {
        var timer = setTimeout( () => {
            console.log("Disconnecting and reconnecting")
            ws.disconnect()
            ws.connect()
        }, 5000);
        // On first render, counter will be 0
        // The condition will be false and setTimeout() won't start
            
            
        return () => clearTimeout(timer);
    
    }, [bin1, ws]);

    function closeModal() {
        setForceClose(true)
    }
    const customStyles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            
          },
        content : {
          top                   : '50%',
          left                  : '50%',
          right                 : 'auto',
          bottom                : 'auto',
          marginRight           : '-50%',
          transform             : 'translate(-50%, -50%)',
          background            : 'clear',
          border: 0
        }
      }
    return (
        <div className="panelSet">
            <div className="twoPanel">
                <Modal
                    // isOpen={((status === 0 || bins.length === 0) && !forceClose)}
                    isOpen={false}
                    onRequestClose={closeModal}
                    onClick={closeModal}
                    style={customStyles}
                    >
                    <div className="WaitingOverlay__panel" >
                        <div>
                            <div className="WaitingOverlay__loader"></div>
                            <div className="WaitingOverlay__loader-text">{bins.length === 0 ? "WAITING FOR BINS" : "RECONNECTING..."}</div>
                        </div>
                    </div>
                    </Modal>
                <div className="twoPanel__panel">
                    <Header user={user} userType={userType} logout={logoutFromBoard} showGear={false} binNumber={1}></Header>
                    <div className="twoUp__table-header">
                        <div className="twoUp__table-row-content">
                            <span className="twoUp_rowheaderspan">
                                NAME
                                </span>
                            <span className="twoUp_headerseparator"></span>
                            <span className="twoUp_rowheaderspan">
                                LSM ID
                            </span>
                            <span className="twoUp_headerseparator"></span>
                            <span className="twoUp_rowheaderspan">
                                DURATION
                            </span>
                        </div>
                </div>
                <OneTable
                    transformedBin={transformedBin1}
                    bin1={bin1}
                    userType={userType}
                    unwatchedAssets={unwatched1Assets}
                    watchedAssets={watched1Assets}>

                    </OneTable>
                </div>
            </div>
            <div className="twoPanel">
                <Modal
                    // isOpen={((status === 0 || bins.length === 0) && !forceClose)}
                    isOpen={false}
                    onRequestClose={closeModal}
                    onClick={closeModal}
                    style={customStyles}
                    >
                    <div className="WaitingOverlay__panel" >
                        <div>
                            <div className="WaitingOverlay__loader"></div>
                            <div className="WaitingOverlay__loader-text">{bins.length === 0 ? "WAITING FOR BINS" : "RECONNECTING..."}</div>
                        </div>
                    </div>
                    </Modal>
                <div className="twoPanel__panel">
                    <Header user={user} userType={userType} logout={logoutFromBoard} showGear={true} binNumber={2}></Header>
                    <div className="twoUp__table-header">
                        <div className="twoUp__table-row-content">
                            <span className="twoUp_rowheaderspan">
                                NAME
                                </span>
                            <span className="twoUp_headerseparator"></span>
                            <span className="twoUp_rowheaderspan">
                                LSM ID
                            </span>
                            <span className="twoUp_headerseparator"></span>
                            <span className="twoUp_rowheaderspan">
                                DURATION
                            </span>
                        </div>
                </div>
                <OneTable
                    transformedBin={transformedBin2}
                    bin1={bin2}
                    userType={userType}
                    unwatchedAssets={unwatched2Assets}
                    watchedAssets={watched2Assets}>

                </OneTable>
                </div>
            </div>
        </div>
    )
}

export default TwoUp;