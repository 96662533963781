import update from 'immutability-helper';
import { BINS1_REORDER, 
    BINS1_REORDER_SHIFT,
    BINS1_UPDATED, 
    BOARD1BIN_SELECTED,
    BINS2_REORDER, 
    BINS2_REORDER_SHIFT,
    BINS2_UPDATED, 
    BOARD2BIN_SELECTED,
    DRAGGING_STARTED,
    DRAGGING_ENDED,
    CONNECTION_STATUS_UPDATED,
    SETTINGS_UPDATED,
    UPDATE_TRANSFORMED_BINS1,
    UPDATE_TRANSFORMED_BINS2,
    RESET,
    // INITIALIZED,
    FONTSIZE_UPDATED
} from './actions'
const initialState = {
    status:0,
    // hasInitializedSocket:false,
    isDragging:false,
    bins:[],
    bin1:{},
    bin1ID:null,
    bin2ID:null,
    bin2:{},
    assetsUnwatched1:[],
    assetsWatched1:[],
    assetsUnwatched2:[],
    assetsWatched2:[],
    tempReorderTransform1:[],
    tempReorderTransform2:[],
    settings:{},
    fontSize:localStorage.getItem("fontSize") ? localStorage.getItem("fontSize") : "16.0"
    // tempSettings:{}
}
export default function binsReducer(state, action) {
    if (typeof state === 'undefined') {
        return initialState
    }

    switch(action.type){
        case BINS1_REORDER:
            state.tempReorderTransform1 = update(state.tempReorderTransform1, {$push:[action.payload]})
            break
        case BINS1_REORDER_SHIFT:
            state.tempReorderTransform1 = update(state.tempReorderTransform1, {$splice: [[0, 1]]})
            break
        case BINS1_UPDATED:         
            if(!state.isDragging)   
                state.bins = action.payload
            break
        case BOARD1BIN_SELECTED:
            state.bin1 = action.payload
            state.bin1ID = action.payload.id
            break
        case BINS2_REORDER:
            state.tempReorderTransform2 = update(state.tempReorderTransform2, {$push:[action.payload]})
            break
        case BINS2_REORDER_SHIFT:
            state.tempReorderTransform2 = update(state.tempReorderTransform2, {$splice: [[0, 1]]})
            break
        case BINS2_UPDATED:         
            if(!state.isDragging)   
                state.bins = action.payload
            break
        case BOARD2BIN_SELECTED:
            state.bin2 = action.payload
            state.bin2ID = action.payload.id
            break
        case UPDATE_TRANSFORMED_BINS1: 
            let unwatched = [], watched = []
            if(action.payload.mediaAssets && action.payload.mediaAssets.length > 0) {
                action.payload.mediaAssets.forEach((v, i) => {
                    if(!v.played) {
                        unwatched.push(v)
                    } else {
                        watched.push(v)
                    }
                })
            }
            state.assetsUnwatched1 = unwatched
            state.assetsWatched1 = watched
            break

            case CONNECTION_STATUS_UPDATED:
            state.status = action.payload
            if(action.payload === 0) {
                state.isDragging = false
                state.bins = []
                state.bin1 = {}
                state.bin2 = {}
                state.assetsUnwatched1 = []
                state.assetsWatched1 = []
                state.tempReorderTransform1 = []
                state.assetsUnwatched2 = []
                state.assetsWatched2 = []
                state.tempReorderTransform2 = []
                state.settings = {}               
            }
            break
        case UPDATE_TRANSFORMED_BINS2:
            let unwatched2 = [], watched2 = []
            if(action.payload.mediaAssets && action.payload.mediaAssets.length > 0) {
                action.payload.mediaAssets.forEach((v, i) => {
                    if(!v.played) {
                        unwatched2.push(v)
                    } else {
                        watched2.push(v)
                    }
                })
            }
            state.assetsUnwatched2 = unwatched2
            state.assetsWatched2 = watched2
            break

            case CONNECTION_STATUS_UPDATED:
            state.status = action.payload
            if(action.payload === 0) {
                state.isDragging = false
                state.bins = []
                state.bin1 = {}
                state.bin2 = {}
                state.assetsUnwatched1 = []
                state.assetsWatched1 = []
                state.tempReorderTransform1 = []
                state.assetsUnwatched2 = []
                state.assetsWatched2 = []
                state.tempReorderTransform2 = []
                state.settings = {}               
            }
            break
        case DRAGGING_STARTED:
            state.isDragging = true
            break
        case DRAGGING_ENDED:
            state.isDragging = false
            break
        case SETTINGS_UPDATED:
            state.settings = action.payload
            break
        case FONTSIZE_UPDATED:
            localStorage.setItem("fontSize", action.payload)
            state.fontSize = action.payload
            break
        case RESET:
            state.status = 0
            state.isDragging = false
            state.bins = []
            state.bin1 = {}
            state.bin1ID = null
            state.bin2ID = null
            state.assetsUnwatched1 = []
            state.assetsWatched1 = []
            state.tempReorderTransform1 = []
            state.bin2 = {}
            state.assetsUnwatched2 = []
            state.assetsWatched2 = []
            state.tempReorderTransform2 = []
            state.settings = {}
            state.fontSize = "16.0"
            break
        default:
            break
    }

    return state
}