export const DRAGGING_STARTED = "DRAGGING_STARTED"
export const DRAGGING_ENDED = "DRAGGING_ENDED"

export function draggingStarted() {
    return {
        type: DRAGGING_STARTED
    }
}
export function draggingEnded() {
    return {
        type: DRAGGING_ENDED
    }
}

export const BINS1_REORDER = "BINS1_REORDER"
export const BINS1_UPDATED = "BINS1_UPDATED"
export const BINS1_REORDER_SHIFT = "BINS1_REORDER_SHIFT"
export const BINS1_REORDER_CLEAR = "BINS1_REORDER_CLEAR"

export function bins1Reorder(transform){
    return {
        type: BINS1_REORDER,
        payload: transform
    }
}

export function bins1Updated(bins) {
    return {
        type:BINS1_UPDATED,
        payload:bins
    }
}

export function bins1ReorderShift() {
    return {
        type:BINS1_REORDER_SHIFT
    }
}
export function bins1ReorderClear() {
    return {
        type:BINS1_REORDER_CLEAR
    }
}

export const BOARD1BIN_SELECTED = "BOARD1BIN_SELECTED"
export function board1BinSelected(bin) {    
    return {
        type:BOARD1BIN_SELECTED,
        payload:bin
    }
}

export const BINS2_REORDER = "BINS2_REORDER"
export const BINS2_UPDATED = "BINS2_UPDATED"
export const BINS2_REORDER_SHIFT = "BINS2_REORDER_SHIFT"
export const BINS2_REORDER_CLEAR = "BINS2_REORDER_CLEAR"

export function bins2Reorder(transform){
    return {
        type: BINS2_REORDER,
        payload: transform
    }
}

export function bins2Updated(bins) {
    return {
        type:BINS2_UPDATED,
        payload:bins
    }
}

export function bins2ReorderShift() {
    return {
        type:BINS2_REORDER_SHIFT
    }
}
export function bins2ReorderClear() {
    return {
        type:BINS2_REORDER_CLEAR
    }
}

export const BOARD2BIN_SELECTED = "BOARD2BIN_SELECTED"
export function board2BinSelected(bin) {    
    return {
        type:BOARD2BIN_SELECTED,
        payload:bin
    }
}

export const CONNECTION_STATUS_UPDATED = "CONNECTION_STATUS_UPDATED"
export function connectionStatusUpdated(status) {
    return {
        type:CONNECTION_STATUS_UPDATED,
        payload:status
    }
}

export const SETTINGS_UPDATED = "SETTINGS_UPDATED"
export function settingsUpdated(settings) {
    return {
        type:SETTINGS_UPDATED,
        payload:settings
    }
}

export const FONTSIZE_UPDATED = "FONTSIZE_UPDATED"
export function fontSizeUpdated(size) {
    return {
        type:FONTSIZE_UPDATED,
        payload:size
    }
}

export const UPDATE_TRANSFORMED_BINS1 = "UPDATE_TRANSFORMED_BINS1"
export function transformBins1(bin) {
    return {
        type:UPDATE_TRANSFORMED_BINS1,
        payload:bin
    }
}
export const UPDATE_TRANSFORMED_BINS2 = "UPDATE_TRANSFORMED_BINS2"
export function transformBins2(bin) {
    return {
        type:UPDATE_TRANSFORMED_BINS2,
        payload:bin
    }
}

export const RESET = "RESET"
export function reset() {
    return {
        type:RESET
    }
}