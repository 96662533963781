import React, { createContext } from 'react'
import io from 'socket.io-client'
import { useDispatch/*, useStore*/ } from 'react-redux'
import { transformBin } from './Helpers'
import { WS_BASE } from './config.js'
import {
  bins1Reorder,
  bins1ReorderShift,
  bins2Reorder,
  bins2ReorderShift,
  bins1Updated,
  bins2Updated,
  board1BinSelected,
  board2BinSelected,
  connectionStatusUpdated,
  settingsUpdated,
} from './actions'

const WebSocketContext = createContext(null)

export { WebSocketContext }

export default ({ children, user }) => {
  // const store = useStore()

  let socket
  let ws

  const dispatch = useDispatch()
  const connect = () => {
    socket.open()
    console.log('SOCKET: Opening');
  }
  const disconnect = () => {
    socket.close()
    console.log('SOCKET: Closed');
  }
  const selectedBinIDs = (binIDs) => {
    
    socket.emit('event', {
      type: 'cmd',
      payload: {
        site: 'TW_HQ',
        service: 'ipd',
        updateMe: true,
        bins: binIDs,
        cmd: 'getIpdBinMediaAssets'
      }
    })
  }

  const reorderBin = (bin, binNumber, sourceIndex, destinationIndex) => {
    let ids = bin.mediaAssets.map((mediaAsset) => {
      return mediaAsset.id
    })

    const transform = {
      sourceID: ids[sourceIndex],
      sourceIDInsertTo: destinationIndex
    }
    switch (binNumber) {
      case 1:
        dispatch(bins1Reorder(transform))
        break
      default:
        break
    }

    let x = ids[sourceIndex]
    ids.splice(sourceIndex, 1)
    ids.splice(destinationIndex, 0, x)

    socket.emit('event', {
      type: 'cmd',
      payload: {
        site: 'TW_HQ',
        service: 'ipd',
        updateMe: false,
        order: ids,
        bin: bin.id,
        cmd: 'setSortOrder'
      }
    }, () => {
      const newBin = transformBin(bin, [transform])
      switch (binNumber) {
        case 1:
          dispatch(board1BinSelected(newBin))
          dispatch(bins1ReorderShift())
          break
        case 2:
          dispatch(board2BinSelected(newBin))
          dispatch(bins2ReorderShift())
          break;
        default:
          break
      }
    })
  }

  const adminMarkerUpdated = (bin, destinationIndex) => {
    // dispatch(settingsUpdating({[bin.id]: {
    //   rowIndex: destinationIndex
    // }}))
    socket.emit('event', {
      type: 'cmd',
      payload: {
        site: 'TW_HQ',
        service: 'ipd',
        cmd: 'updateSettings',
        settings: {
          [bin.id]: {
            rowIndex: destinationIndex
          }
        }
      }
    }, (settings) => {
      console.log("Success: ", settings)
      dispatch(settingsUpdated(settings))
    })
  }

  const markAsPlayed = (binID, assetID, played, cb) => {
    console.log('Updating Played status for BINID: ', binID, " assetID: ", assetID, " asplayed: ", played)
    socket.emit('event', {
      type: 'cmd',
      payload: {
        site: 'TW_HQ',
        service: 'ipd',
        bin: binID,
        assetId: assetID,
        played: played,
        cmd: 'setPlayed',
      }
    }, (updatedBin) => {
      console.log('Update complete', updatedBin)
      dispatch(board1BinSelected(updatedBin))
      dispatch(board2BinSelected(updatedBin))
      cb()
    })
  }

  if (!socket && user && user.accessToken) {
    console.log("calling")
  
    socket = io(WS_BASE, {
      path: '/api',
      transportOptions: {
        polling: {
          extraHeaders: {
            'Authorization': `Bearer ${user.accessToken}`
          }
        }
      }
    })

    socket.on("connect", (message) => {
      console.log("SOCKET: connected")
      console.log("SOCKET: emitting getIpdBins")
      socket.emit('event', {
        type: 'cmd',
        payload:
        {
          cmd: 'getIpdBins',
          service: 'ipd',
          site: 'TW_HQ',
        }
      })

      socket.emit('event', {
        type: 'cmd',
        payload: {
          site: 'TW_HQ',
          service: 'ipd',
          cmd: 'getSettings',
        }
      }, (settings) => {
        console.log("Got new settings: ", settings)
        if (!settings) {
          console.log("invalid settings")
          return
        }
        dispatch(settingsUpdated(settings))
      })

      dispatch(connectionStatusUpdated(2))
    })

    socket.on("event", (data) => {
      const { type, payload } = data
      if (type === 'response' && payload.response === 'ipdBins') {
        if (payload.error) {
          console.log('Error getting bins', payload.error)
        } else {
          console.log('Got bins: ', data.payload.bins)
          dispatch(bins1Updated(data.payload.bins))
          dispatch(bins2Updated(data.payload.bins))
        }
      } else if (type === 'response' && payload.response === 'binsWithAssets') {
        console.log("binswithassets")
        console.log(payload.binsWithAssets)
        if (payload.error) {
          console.log('Error getting assets', payload.error)
        } else {
          dispatch(board1BinSelected(payload.binsWithAssets[0]))
          if(payload.binsWithAssets.length == 2) {
            dispatch(board2BinSelected(payload.binsWithAssets[1]))
          }
          // dispatch(board2BinSelected(payload.binsWithAssets[0]))
          // dispatch(bins1ReorderClear())
        }
      } else if (type === 'response' && payload.response === 'settings') {
        if (payload.error) {
          console.log('Error getting settings', payload.error)
        } else {
          dispatch(settingsUpdated(payload.settings))
        }
      }

      //Status messages show plugins coming on/offline and when a plugin gets an error
      // if (type === 'message' && payload.message === 'pluginStatus') {
      // console.log('STATUS MESSAGE')
      // console.log(data)
      // }
    })

    socket.on("disconnect", () => {
      console.log("SOCKET: disconnected")
      dispatch(connectionStatusUpdated(0))
    })

    ws = {
      socket: socket,
      selectedBinIDs,
      disconnect,
      connect,
      reorderBin,
      markAsPlayed,
      adminMarkerUpdated
      // sendMessage
    }
  }

  return (
    <WebSocketContext.Provider value={ws}>
      {children}
    </WebSocketContext.Provider>
  )
}