import React, { useState } from 'react'
import { motion } from "framer-motion"
import { Redirect } from "react-router-dom"
import './Login.css'


function Login({user, setUser, auth}) {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [loginError, setLoginError] = useState(false)

  function handleEnter(e) {       
      if(e.key === "Enter") { 
        signin(username, password)
      }
  }
  function handleClick() {
    signin(username, password)
  }

  function signin(username, password) {       
    return new Promise((resolve, reject) => {
      auth.client.login(
        {realm:"Username-Password-Authentication", username, password},
        (err, authResult) => {
          if (err) {
            setLoginError(false) //to flash it
            setLoginError(true)
            return reject(err)
          }
          auth.client.userInfo(authResult.accessToken, (err, result) => {
            if(err) {
              return reject(err)
            }
            
            let expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime())
            localStorage.setItem('accessToken', authResult.accessToken)
            localStorage.setItem('idToken', authResult.idToken)
            localStorage.setItem('expiresAt', expiresAt)
            localStorage.setItem('nickname', result.nickname)
            localStorage.setItem('roles', JSON.stringify(result["https://viewer-api.thumbwar.tv/roles"]))

            setUser({
              accessToken:authResult.accessToken,
              idToken:authResult.idToken,
              expiresAt:authResult.expiresAt,
              name:result.nickname,
              roles:result["https://viewer-api.thumbwar.tv/roles"]
            })
            return resolve()
          })

          
        }
      )
    })
  }


  return (
    <div className="Login">
      {user ? <Redirect push to="/oneup"/> : null}
      <motion.div
        className="Login__container"
        initial={{y:5 }}
          transition={{duration:0.5, delay:0.0, ease:"easeOut" }}
          animate={{y:0 }}>
        <div className="Login__titlecontainer">
          <div className="Login__title">
            <motion.span initial={{opacity:0.0, x:10 }} transition={{duration:0.5, delay:0.0, ease:"easeOut" }} animate={{opacity: 1.0, x:0 }} className="Login__company"><img src="/images/tw_stack_transparent_white.png" alt="logo"/></motion.span>
            <motion.span initial={{opacity:0.0, x:10 }} transition={{duration:0.5, delay:0.1, ease:"easeOut" }} animate={{opacity: 1.0, x:0 }} className="Login__project">BOARDS</motion.span>
            </div>
        </div>
        <motion.div initial={{ scale: 0.0, opacity:0.0 }} transition={{duration:1.5, delay:0.0, ease:[.07,.96,.14,.96] }} animate={{ scale: 1.0, opacity: 1.0 }} className="Login__vl">

        </motion.div>
        <div className="Login__fields">
          <motion.input 
          initial={{opacity:0.0, x:-10 }} 
          transition={{duration:0.5, delay:0.0, ease:"easeOut" }} 
          animate={{opacity: 1.0, x:0 }} 
          input 
          className="Login__username" 
          onKeyPress={handleEnter} 
          onChange={e => setUsername(e.target.value)}
          placeholder="USERNAME"></motion.input>
          <motion.input 
            initial={{opacity:0.0, x:-10 }} 
            transition={{duration:0.5, delay:0.1, ease:"easeOut" }} 
            animate={{opacity: 1.0, x:0 }} 
            input 
            className="Login__password" 
            onKeyPress={handleEnter} 
            placeholder="PASSWORD" 
            type="password"
            onChange={e => setPassword(e.target.value)}
            ></motion.input>
          <div className="Login__footer">
            {loginError ? <motion.span 
              className="Login__invalid"
              initial={{opacity:0.0 }} 
              transition={{duration:0.5, delay:0, ease:"easeOut" }} 
              animate={{opacity: 1.0 }} >
              INVALID CREDENTIALS
            </motion.span> : null}
            
            <span className="Login__login">
            <motion.img 
              className="" 
              initial={{opacity:0.0, x:-10 }} 
              transition={{delay:0.0, duration:0.5, ease:"easeOut" }} 
              onClick={() => handleClick()} 
              animate={{opacity: 1.0, x:0 }} 
              src="/images/login.png" 
              alt=""
              />
            </span>
          </div>
        </div>
      </motion.div>
    </div>
  )
}

export default Login
