import React, {useState, useEffect} from "react"
import OneUp from "./OneUp"
import TwoUp from "./TwoUp"
import WebSocketProvider from './WebSocket';
import Login from "./Login"
import {
    Switch,
    Route
} from "react-router-dom";
import { useHistory } from "react-router-dom"
import "./App.scss";
import auth0 from 'auth0-js'
import { AUTH0_DOMAIN, AUTH0_CLIENT_ID } from './config'
const useEffectOnlyOnce = (func) => useEffect(func, [])

function App() {    
    const [user, setUser] = useState({})
    // const [checkingUser, setCheckingUser] = useState(true)
    const history = useHistory();
    
    let auth = new auth0.WebAuth({
      
      domain: AUTH0_DOMAIN,
      clientID: AUTH0_CLIENT_ID,
      audience: "https://viewer-api.thumbwar.tv",
      redirectUri: window.location.origin,
      scope: "openid profile email"
    })
    useEffectOnlyOnce(() => {
    
        const idToken = localStorage.getItem('idToken')
        if (idToken != null) {      
          setUser({
            accessToken:localStorage.getItem('accessToken'),
            idToken:idToken,
            expiresAt:localStorage.getItem('expiresAt'),
            roles:JSON.parse(localStorage.getItem('roles')),
            name:localStorage.getItem('nickname')
          })
          // auth.checkSession({audience: "https://viewer-api.thumbwar.tv", responseType:"token"}, function (err, authResult) {
          //   console.log('err:', err);
          //   console.log('authResult:', err);
            
          // })
          auth.client.userInfo(localStorage.getItem('accessToken'), (err, result) => {
            if(err) {
              setUser({})
              logout()
            } else {
              setUser({
                accessToken:localStorage.getItem('accessToken'),
                idToken:idToken,
                expiresAt:localStorage.getItem('expiresAt'),
                roles:JSON.parse(localStorage.getItem('roles')),
                name:localStorage.getItem('nickname')
              })
            }
            // setCheckingUser(false)
          })
        } else {
          logout()
          // setCheckingUser(false)
        }
      })

      function logout() {
        localStorage.clear();
        setUser(null)
        history.push('/');
      }

    return (
      <WebSocketProvider user={user}>
        <div className="App">
            <Switch>
                <Route path="/oneup">                    
                  <OneUp user={user} logout={logout}/>
                </Route>
                <Route path="/twoup">
                  <TwoUp user={user} logout={logout}/>
                </Route>
                <Route path="/">
                    <Login user={user} setUser={setUser} auth={auth}></Login>
                </Route>
            </Switch>
        </div>
      </WebSocketProvider>
    )
}

export default App;